
import { Component, Vue } from 'vue-property-decorator';
import {
  getReservationReviewLikeList,
  getReservationReviewMypageTotal,
  getReservationReviewOwnList,
  deleteReservationReview,
} from '@/api/reservationReview';
import { likeReview } from '@/api/reservationReviewLike';
import { UserModule } from '@/store/user';
import InfiniteLoading from 'vue-infinite-loading';
import { getUserInfo } from '@/api/user';
import { getPointInfo } from '@/api/point';
import router from '@/router';

@Component({
  components: {
    InfiniteLoading,
  },
})

export default class extends Vue {
  mounted() {
    this.init();
    this.getUserInfo();
  }

  get actualName() {
    return UserModule.actualName;
  }

  private tab = 'review';

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private maxLevelCnt = 0;

  private reviewListQuery = {
    page: 0,
    size: 10,
  }

  private optionInfo : { optionImgUrl: String, optionName: String } = { optionImgUrl: '', optionName: '' };

  private levelOptionList: { optionPoint: number, optionName: string, imgUrl : string }[] = [];// 포인트 옵션

  // 포인트 옵션
  private getPointInfo() {
    getPointInfo().then((res) => {
      this.levelOptionList = res.data;
      this.mylevel();
    });
  }

  private handleDeleteItem(review: any) {
    this.$confirm('정말 리뷰를 삭제하시겠습니까?', '댓글 삭제', {
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      deleteReservationReview(review.idx).then(() => {
        this.$message.success('리뷰가 삭제되었습니다.');
        this.getReviewList();
        this.goBack();
      });
    });
  }

  // 유저정보
  private getUserInfo() {
    getUserInfo().then((res) => {
      this.userInfo = res.data;
      this.getPointInfo();
    });
  }

  private userInfo = {
    actualName: '',
    concatNumber: '',
    point: 0,
  }

  private mylevel() {
    this.maxLevelCnt = this.levelOptionList.length;
    for (let i = this.maxLevelCnt - 1; i >= 0; i -= 1) {
      const level: { optionPoint: number, optionName: string, imgUrl : string } = this.levelOptionList[i];
      if (Number(this.userInfo.point) >= Number(level.optionPoint)) {
        this.optionInfo.optionName = level.optionName;
        this.optionInfo.optionImgUrl = level.imgUrl;
        break;
      }
    }
  }

  private reviewList = [];

  private totalReview = 0;

  private reviewNoMore = false;

  private likeListQuery = {
    page: 0,
    size: 10,
  }

  private likeList = [];

  private totalLike = 0;

  private likeNoMore = false;

  private loading = true;

  private init() {
    getReservationReviewMypageTotal().then((res) => {
      this.totalReview = res.data.totalReview;
      this.totalLike = res.data.totalLike;
    });
    if (this.$route.query.tab) {
      if (this.$route.query.tab === 'like') this.tab = 'like';
    }
    if (this.tab === 'review') this.getReviewList();
    if (this.tab === 'like') this.getLikeList();
  }

  private getReviewList() {
    this.loading = true;
    getReservationReviewOwnList(this.reviewListQuery).then((res) => {
      this.reviewList = this.reviewList.concat(res.data.content);
      this.reviewNoMore = res.data.last;
      this.loading = false;
    });
  }

  private getLikeList() {
    this.loading = true;
    getReservationReviewLikeList(this.reviewListQuery).then((res) => {
      this.likeList = this.likeList.concat(res.data.content);
      this.likeNoMore = res.data.last;
      this.loading = false;
    });
  }

  private handleClickLike(review: any) {
    likeReview(review.idx).then(() => {
      if (review.likeStatus) {
        this.$message.success('리뷰 좋아요를 취소했습니다.');
        review.likeStatus = false;
        review.likeCount -= 1;
      } else {
        this.$message.success('리뷰 좋아요를 등록했습니다.');
        review.likeStatus = true;
        review.likeCount += 1;
      }
      getReservationReviewMypageTotal().then((res) => {
        this.totalReview = res.data.totalReview;
        this.totalLike = res.data.totalLike;
      });
    }).catch(() => {
      this.$message.error('요청에 실패했습니다.');
    });
    getReservationReviewMypageTotal().then((res) => {
      this.totalReview = res.data.totalReview;
      this.totalLike = res.data.totalLike;
    });
  }

  private handleCancelLike(like: any, index: number) {
    likeReview(like.idx).then(() => {
      this.$message.success('리뷰 좋아요를 취소했습니다.');
      this.likeList.splice(index, 1);
      getReservationReviewMypageTotal().then((res) => {
        this.totalReview = res.data.totalReview;
        this.totalLike = res.data.totalLike;
      });
    }).catch(() => {
      this.$message.error('요청에 실패했습니다.');
    });
  }

  private handleClickTab() {
    if (this.tab === 'review') this.getReviewList();
    if (this.tab === 'like') this.getLikeList();
    this.reviewList = [];
    this.likeList = [];
  }

  private goBack() {
    this.$router.go(-1);
  }
}
