import request from '@/utils/request';

const PATH = '/reservation/review';

export const getReservationReviewList = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const getReservationReviewMypageTotal = () => request({
  url: `${PATH}/mypage/total`,
  method: 'get',
});

export const getReservationReviewOwnList = (params: any) => request({
  url: `${PATH}/own`,
  method: 'get',
  params,
});

export const getReservationReviewLikeList = (params: any) => request({
  url: `${PATH}/like`,
  method: 'get',
  params,
});

export const addReservationReview = (clientUid: any, data: any) => request({
  url: `${PATH}/${clientUid}`,
  method: 'post',
  data,
});

export const updateReservationReview = (idx: any, data: any) => request({
  url: `${PATH}/${idx}`,
  method: 'put',
  data,
});

export const deleteReservationReview = (idx: any) => request({
  url: `${PATH}/${idx}`,
  method: 'delete',
});
